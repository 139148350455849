import React from "react"
import styles from "./card1.module.css"
import pic1 from "../images/c1_image.png"
import pic2 from "../images/c3_image.png"
import { Link } from "gatsby"

const Card1 = ({ type }) => {
  return (
    <div
      id="home"
      className={type === 2 ? styles.card3_container : styles.container}
    >
      <div className={styles.row}>
        <div className={styles.columnb}>
          <div>
            {type === 1 ? (
              <>
                <div className={styles.head}>
                  Close deals <span className={styles.highlight}>faster</span>{" "}
                  and more <span className={styles.highlight}>frequently</span>{" "}
                  than ever before!
                </div>
                <div className={styles.main}>
                  FinSimpl <span className={styles.highlight}>guarantees</span>{" "}
                  to match your customers with the perfect lender, ensuring they
                  get the <span className={styles.highlight}>best deal</span>{" "}
                  every time.
                </div>
                <div className={styles.main}>
                  Start offering your customers with financing they{" "}
                  <span className={styles.highlight}>
                    simply can’t say no to!
                  </span>
                </div>
                <Link to="/login/" state={{ checkAuthStatus: true }}>
                  <div className={styles.button}>
                    <div className={styles.button_text}>Get Started</div>
                  </div>
                </Link>
              </>
            ) : type === 2 ? (
              <>
                <div className={styles.head}>
                  Get <span className={styles.highlight}>fast funding</span> for
                  your customers.
                </div>
                <div className={styles.main}>
                  Our <span className={styles.highlight}>trusted lenders</span>{" "}
                  guarantee that your customers can get{" "}
                  <span className={styles.highlight}>funded in 1-3 days</span>.
                </div>
                <div className={styles.main}>
                  Join today and help us{" "}
                  <span className={styles.highlight}>grow your revenue</span>,
                  for FREE.
                </div>
                <Link to="/login/" state={{ checkAuthStatus: true }}>
                  <div className={styles.button}>
                    <div className={styles.button_text}>Join Today</div>
                  </div>
                </Link>
              </>
            ) : null}
          </div>
        </div>
        <div className={styles.columna}>
          {type === 1 ? (
            <>
              <img
                src={pic1}
                className={styles.imgs}
                height="626"
                width="900"
                alt="Painters"
              />
            </>
          ) : type === 2 ? (
            <>
              <img
                src={pic2}
                className={styles.imgs}
                height="626"
                width="900"
                alt="Getting Funding"
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Card1
