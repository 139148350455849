import React from "react"

import Layout from "../components/layout"
import Menu from "../components/menu"
import Card1 from "../components/card1"
import Card2 from "../components/card2"
import Benefits from "../components/benefits"
import Pricing from "../components/pricing"
import FAQ from "../components/faq"
import Popup from "../components/popup"
import Footer from "../components/footer"

const IndexPage = () => (
  <Layout type={1}>
    <Menu type={1} btype={1} />
    <Card1 type={1} />
    <Card2 />
    <Card1 type={2} />
    <Benefits type={1} />
    <Pricing />
    <FAQ type={1} />
    <Popup />
    <Footer type={1} />
  </Layout>
)

export default IndexPage
