import React from "react"
import styles from "./popup.module.css"

const Popup = () => {
  function closeMessage() {
    document.getElementById("mess").style.setProperty("display", "none")
  }

  return (
    <div className={styles.message} id="mess">
      <div className={styles.message_content}>
        <p>This website is better optimized to be viewed on a browser</p>
        <a onClick={closeMessage} href="/">
          {" "}
          &#10006;{" "}
        </a>
      </div>
    </div>
  )
}

export default Popup
