import React from "react"
import styles from "./card2.module.css"
import pic from "../images/c2_image.png"
import { Link } from "gatsby"

const Card2 = () => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.columnb}>
          <img
            src={pic}
            className={styles.imgs}
            height="626"
            width="976"
            alt="Quote Calculator Link"
          />
        </div>
        <div className={styles.columna}>
          <div className={styles.head}>
            “<span className={styles.highlight}>$30,000</span> for the
            renovations? Nope, it's{" "}
            <span className={styles.highlight}>$300 / month</span>”
          </div>
          <div className={styles.main}>
            Grab your{" "}
            <span className={styles.highlight}>customer’s attention</span> by
            starting the conversation with{" "}
            <span className={styles.highlight}>amazing low quotes.</span>
          </div>
          <div className={styles.main}>
            Seal the deal <span className={styles.highlight}>RIGHT NOW</span>,
            by using our custom quote calculator. Click below to test it out!
          </div>
          <Link to="/login/" state={{ checkAuthStatus: true }}>
            <div className={styles.button}>
              <div className={styles.button_text}>Calculate a Quote</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Card2
