import React from "react"
import styles from "./pricing.module.css"
import pic from "../images/c4_image.png"
import { Link } from "gatsby"

const Pricing = () => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.columnb}>
          <img
            src={pic}
            className={styles.imgs}
            height="626"
            width="900"
            alt="FinSimpl Pricing"
          />
        </div>
        <div className={styles.columna}>
          <div id="pricing" className={styles.head}>
            FinSimpl is <span className={styles.highlight}>FREE</span> to use!
            We work with{" "}
            <span className={styles.highlight}>all contractors</span>.
          </div>
          <div className={styles.main}>
            You can use FinSimpl and get{" "}
            <span className={styles.highlight}>unlimited access</span> to
            fundings for your clients.
          </div>
          <div className={styles.main}>
            FinSimpl helps{" "}
            <span className={styles.highlight}>any type of contractor</span>{" "}
            generate millions of{" "}
            <span className={styles.highlight}>new sales</span> each year.
          </div>
          <Link to="/login/" state={{ checkAuthStatus: true }}>
            <div className={styles.button}>
              <div className={styles.button_text}>Get Started</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Pricing
